import React from 'react';
import '../style/Cards.css';
import CardItem from './CardItem';

function Cards() {
  return (
    <div className='cards'>
      <h1>Current Running Projects!</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='images/house.jfif'
              text='Find your dream home effortlessly with our app, offering a vast property database, personalized search, and real-time updates on new listings.'
              label='Real Estate / NESTIN'
              path='/services'
            />
            <CardItem
              src='images/dumble.jpg'
              text='Achieve fitness goals effortlessly with our advanced app that tracks body measurements for seamless training progress.'
              label='Fitness / BUFFALO WINGS'
              path='/services'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/webdesing.png'
              text='Boost your online presence and captivate your audience with our professional web development and design service. '
              label='Web Services / WEB WEAVERS'
              path='/services'
            />
            <CardItem
              src='images/shipping.jpg'
              text='Streamline your order and shipping operations with our intuitive application.'
              label='Shipping / SHIP VERSE'
              path='/services'
            />
            <CardItem
              src='images/workorder.jpg'
              text='Efficiently manage work and purchase orders with our comprehensive application.'
              label='Management / MAKANI'
              path='/services'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
