import React from 'react';
import '../../App.css';
import {Button} from './Button';
import '../style/HeroSection.css';

function HeroSection() {
    return (
        <div className='hero-container'>
            <h1>Amplify Tech Consulting</h1>
            <p>Amplify Your Impact</p>
            <div className='hero-btns'>
                <Button
                    className='btns'
                    buttonStyle='btn--outline'
                    buttonSize='btn--large'
                >
                    GET STARTED
                </Button>
            </div>
        </div>
    );
}

export default HeroSection;
