import React, { useEffect, useState } from 'react';
import '../style/Services.css';
import {
  Animator,
  batch,
  Fade,
  MoveOut,
  ScrollContainer,
  ScrollPage,
  Sticky,
  Move,
  MoveIn
} from "react-scroll-motion";

export default function Services() {
  const [isTighterScreen, setIsTighterScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsTighterScreen(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const FadeUp = batch(Fade(), Sticky(80, 50), Move());

  return (
      <ScrollContainer className='general'>
        <ScrollPage>
            <Animator animation={batch(Sticky(), Fade(), MoveOut(0, -200))}>
            <h1 className='services'>SERVICES</h1>
            <p className='arrow'>&#11167;</p>
          </Animator>     
        </ScrollPage>

        <ScrollPage>
          <Animator animation={batch(Fade(), Sticky(isTighterScreen ? 50 : 20, isTighterScreen ? 35 : 50), Move())}>
            <h2 className='headers'>Web Development</h2>
          </Animator>

          <ScrollPage>
            <Animator animation={batch(MoveIn(3000,0), Sticky(50,50), MoveOut(-3000, 0))}>
            <img src='./images/intellij.png' alt='IntelliJ Idea'/>
            </Animator>
          </ScrollPage>
          <Animator animation={FadeUp}>
          <p>Our web development services are designed to create visually stunning and highly functional websites tailored to your unique business needs. We leverage the latest technologies and industry best practices to ensure your online presence stands out from the competition.</p>
          <p>&nbsp;</p>
          <p>Services offered:</p>
          <p>&nbsp;</p>
            <p>- Custom website development</p>
            <p>- Responsive web design</p>
            <p>- E-commerce solutions</p>
            <p>- Content management systems</p>
            <p>- Website maintenance and support</p>
          </Animator>
        </ScrollPage>

        <ScrollPage>
          <Animator animation={batch(Fade(), Sticky(), Move())}>
            <img src='./images/softwaresolutions.png' alt='web building'/>
          </Animator>
        </ScrollPage>

        <ScrollPage>
        <Animator animation={batch(Fade(), Sticky(isTighterScreen ? 50 : 20, isTighterScreen ? 35 : 50), Move())}>
          <h2 className='headers'>Software Solutions</h2>
          </Animator>
          <Animator animation={batch(Fade(), Sticky(), Move())}>
            <img src='./images/software_progress.png' alt='software progress'/>
            </Animator>
          <Animator animation={FadeUp}>
          <p>Optimize your business operations and increase productivity with our customized software solutions. Our team of skilled developers can create tailor-made applications that streamline your workflows, automate processes, and provide data-driven insights.</p>
          <p>&nbsp;</p>
          <p>Services offered:</p>
          <p>&nbsp;</p>
          <p>- Enterprise software development</p>
          <p>- Cloud-based solutions</p>
          <p>- Database design and management</p>
          <p>- Integration services (API, third-party software)</p>
          <p>- Software maintenance and support</p>
          </Animator>
        </ScrollPage>

        <ScrollPage>
          <Animator animation={batch(Fade(), Sticky(), Move())}>
            <img src='./images/it_consulting.png' alt='it consulting'/>
          </Animator>
        </ScrollPage>

        <ScrollPage>
        <Animator animation={batch(Fade(), Sticky(isTighterScreen ? 50 : 20, isTighterScreen ? 35 : 50), Move())}>
          <h2 className='headers'>IT Consulting</h2>
          </Animator>
          <Animator animation={batch(Fade(), Sticky(), Move())}>
            <img src='./images/it_consultant.png' alt='it consultant'/>
            </Animator>
          <Animator animation={FadeUp}>
          <p>Maximize your technology investments with our IT consulting services. We help businesses assess their existing infrastructure, identify areas for improvement, and develop strategic plans for long-term success. Our expertise spans various industries and technology domains.</p>
          <p>&nbsp;</p>
          <p>Services offered:</p>
          <p>&nbsp;</p>
          <p>- IT infrastructure assessment and planning</p>
          <p>- IT strategy development</p>
          <p>- Cybersecurity consulting</p>
          <p>- Cloud migration and optimization</p>
          <p>- Technology vendor evaluation and selection</p>
          </Animator>
        </ScrollPage>

        <ScrollPage>
          <Animator animation={batch(Fade(), Sticky(), Move())}>
            <img src='./images/marketing.png' alt='marketing'/>
          </Animator>
        </ScrollPage>

        <ScrollPage>
        <Animator animation={batch(Fade(), Sticky(isTighterScreen ? 50 : 20,  isTighterScreen ? 35 : 50), Move())}>
          <h2 className='headers'>Digital Marketing</h2>
          </Animator>
          <Animator animation={batch(Fade(), Sticky(), Move())}>
            <img src='./images/digital_marketing.png' alt='it consultant'/>
            </Animator>
          <Animator animation={FadeUp}>
          <p>Amplify your brand's online presence and reach your target audience effectively with our digital marketing services. We develop data-driven strategies that encompass search engine optimization (SEO), social media marketing, content creation, and more.</p>
          <p>&nbsp;</p>
          <p>Services offered:</p>
          <p>&nbsp;</p>
          <p>- Search engine optimization (SEO)</p>
          <p>- Social media management and advertising</p>
          <p>- Pay-per-click (PPC) campaigns</p>
          <p>- Content marketing and copywriting</p>
          <p>- Analytics and performance tracking</p>
          </Animator>
        </ScrollPage>
        
        <ScrollPage>
          <Animator animation={batch(Fade(), Sticky(), MoveOut(0,-500))}>
            <h2 className='headers'>CONTACT NOW!</h2>
          </Animator>
        </ScrollPage>
      </ScrollContainer>
  );
}
